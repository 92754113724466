<template>
  <section class="soma-residency">
    <h1>Sona Residency</h1>

    <PhotoSwipe folder="sona" :photo-number="15" />

  </section>
</template>

<script>
export default {
  name: 'SonaResidency'
}
</script>

<style lang="scss">
.soma-residency {
}
</style>
